<template>
  <div class="">
    <Breadcrumb />
    <Intro msg="Crea evento" />
    <CreateNewEvent />
  </div>
</template>

<script>
import Intro from "@/components/component/Intro.vue";
import Breadcrumb from "@/components/component/Breadcrumb.vue";
import CreateNewEvent from "@/components/events/Create.vue";

export default {
  name: "CreateEvent",
  components: {
    Intro,
    Breadcrumb,
    CreateNewEvent,
  },
};
</script>
