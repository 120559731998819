<template>
  <div>
    <div class="container px-4 mx-auto">
      <div class="md:w-3/4 xl:w-2/4 mx-auto">
        <div v-if="!submitted">
          <form action="#" @submit.prevent="createEvent()">
            <div class="grid grid-cols-1 gap-4 mb-4">
              <!-- Title -->
              <!-- <div class="mb-6">
                <label class="block text-lg text-red mb-1" for="title"
                  >Titolo</label
                >
                <input
                  type="text"
                  class="w-full border-b focus:outline-none"
                  id="title"
                  name="title"
                  v-model="event.data.title"
                  required
                />
              </div> -->
              <md-field class="border-b mb-6">
                <label class="block text-red mb-1" for="title">Titolo</label>
                <md-input
                  type="text"
                  class="w-full border-b focus:outline-none"
                  id="title"
                  name="title"
                  v-model="event.data.title"
                  required
                ></md-input>
              </md-field>

              <!-- Subtitle -->
              <!-- <div class="mb-6">
                <label class="block text-lg text-red mb-1" for="subtitle"
                  >Sottotitolo</label
                >
                <input
                  type="text"
                  class="w-full border-b focus:outline-none"
                  id="subtitle"
                  name="subtitle"
                  v-model="event.data.subtitle"
                  required
                />
              </div> -->
              <md-field class="border-b mb-6">
                <label class="block text-red mb-1" for="subtitle"
                  >Sottotitolo</label
                >
                <md-input
                  type="text"
                  class="w-full border-b focus:outline-none"
                  id="subtitle"
                  name="subtitle"
                  v-model="event.data.subtitle"
                  required
                ></md-input>
              </md-field>
            </div>

            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
              <!-- Date -->
              <!-- <div class="mb-6">
                <label class="block text-lg text-red mb-1" for="date"
                  >Data</label
                >
                <input
                  type="date"
                  class="w-full border focus:outline-none px-1"
                  id="date"
                  name="date"
                  value=""
                  max=""
                  :format="'MM.DD.YYYY'"
                  v-model="event_data_date"
                  required
                />
              </div> -->
              <div class="mb-6">
                <md-datepicker
                  class="w-full focus:outline-none"
                  id="date"
                  name="date"
                  value=""
                  max=""
                  :format="'MM.DD.YYYY'"
                  v-model="event_data_date"
                  required
                >
                  <label class="block text-red mb-1">Data</label>
                </md-datepicker>
              </div>

              <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                <!-- Time -->
                <div class="md-layout-item">
                  <md-field class="mb-6">
                    <label class="block text-red mb-1" for="hours">Ore</label>
                    <md-select
                      class="w-full border-b focus:outline-none"
                      id="hours"
                      name="hours"
                      v-model="event.data.time.hour"
                      required
                    >
                      <md-option
                        v-for="hour in hours"
                        v-bind:value="hour.value"
                        :key="hour.value"
                        class=""
                      >
                        {{ hour.text }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="md-layout-item">
                  <md-field class="mb-1.0">
                    <label class="block text-red mb-1" for="minutes"
                      >Minuti</label
                    >
                    <md-select
                      class="w-full border-b focus:outline-none"
                      id="minutes"
                      name="minutes"
                      v-model="event.data.time.minute"
                      required
                    >
                      <md-option
                        v-for="minute in minutes"
                        v-bind:value="minute.value"
                        :key="minute.value"
                        class=""
                      >
                        {{ minute.text }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
              <!-- City -->
              <!-- <div class="mb-6">
                <label class="block text-lg text-red mb-1" for="city"
                  >Città</label
                >
                <select
                  class="w-full border focus:outline-none px-1"
                  id="city"
                  name="city"
                  v-model="event.data.city"
                  required
                >
                  <option
                    v-for="city in cities"
                    v-bind:value="city.value"
                    :key="city.value"
                  >
                    {{ city.text }}
                  </option>
                </select>
              </div> -->
              <div class="md-layout-item">
                <md-field class="mb-6">
                  <label class="block text-red mb-1" for="city">Città</label>
                  <md-select
                    class="w-full border-b focus:outline-none"
                    id="city"
                    name="city"
                    v-model="event.data.city"
                    required
                  >
                    <md-option
                      v-for="city in cities"
                      v-bind:value="city.value"
                      :key="city.value"
                      class=""
                    >
                      {{ city.text }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>

              <!-- Location -->
              <!-- <div class="mb-6">
                <label class="block text-lg text-red mb-1" for="location"
                  >Luogo</label
                >
                <input
                  type="text"
                  class="w-full border-b focus:outline-none"
                  id="location"
                  name="location"
                  v-model="event.data.location"
                  required
                />
              </div> -->
              <md-field class="border-b mb-6">
                <label class="block text-red mb-1" for="location">Luogo</label>
                <md-input
                  type="text"
                  class="w-full border-b focus:outline-none"
                  id="location"
                  name="location"
                  v-model="event.data.location"
                  required
                ></md-input>
              </md-field>
            </div>

            <div class="grid grid-cols-1 gap-4 mb-4">
              <!-- Seat -->
              <!-- <div class="mb-6">
                <label class="block text-lg text-red mb-1" for="seats"
                  >Numero posti *</label
                >
                <input
                  type="number"
                  class="w-full border focus:outline-none px-1"
                  id="seats"
                  name="seats"
                  min="1"
                  max="1000"
                  v-model="event.data.seats"
                  required
                />
              </div> -->
              <md-field class="border-b mb-6">
                <label class="block text-red mb-1" for="seats"
                  >Numero posti (modificabile in seguito)
                </label>
                <md-input
                  type="number"
                  class="w-full border-b focus:outline-none"
                  id="seats"
                  name="seats"
                  min="1"
                  v-model="event.data.seats"
                  required
                ></md-input>
              </md-field>
            </div>

            <div class="mb-4">
              <!-- Video conference -->
              <div class="mb-6">
                <md-checkbox
                  class="my-1"
                  id="video_conference"
                  v-bind:value="true"
                  v-model="event.data.video_conference"
                  >Sala videocollegata</md-checkbox
                >
              </div>
            </div>

            <div class="mb-4">
              <!-- Status -->
              <!-- <div class="mb-6">
                <input
                  type="checkbox"
                  class="mr-2"
                  id="draft"
                  name="draft"
                  v-model="event.data.draft"
                />
                <label for="draft">Salva in bozza</label>
              </div> -->
              <div class="mb-6">
                <md-checkbox
                  class="my-1"
                  id="draft"
                  v-bind:value="true"
                  v-model="event.data.draft"
                  >Salva in bozza</md-checkbox
                >
              </div>
            </div>

            <div class="mb-4">
              <div class="mb-4">
                <p class="mb-6">* Campo obbligatorio</p>
              </div>

              <!-- Submit -->
              <div class="mb-6">
                <input
                  class="
                    bg-red
                    text-md text-white
                    uppercase
                    border border-red
                    px-4
                    py-1
                    hover:text-red hover:bg-white
                    cursor-pointer
                    transition-colors
                    duration-500
                    ease-in-out
                  "
                  type="submit"
                  value="Crea"
                />
              </div>
            </div>
          </form>
        </div>

        <div v-else class="text-center">
          <div class="mb-6">
            <h4>Evento creato correttamente.</h4>
          </div>
          <div class="mb-6">
            <button
              class="
                bg-red
                text-md text-white
                uppercase
                border border-red
                px-4
                py-1
                hover:text-red hover:bg-white
                cursor-pointer
              "
              @click="newCreateEvent()"
            >
              Crea evento
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import EventsDataService from "@/services/EventsDataService";

export default {
  name: "Create",
  data() {
    return {
      event_data_date: "",
      event: {
        data: {
          title: "",
          subtitle: "",
          day: "",
          date: "",
          time: {
            hour: "",
            minute: "",
          },
          // time_hour: null,
          // time_minute: null,
          city: "",
          location: "",
          seats: 0,
          draft: false,
          video_conference: false,
        },
      },
      cities: [
        { text: "Torino", value: "Torino" },
        { text: "Novara", value: "Novara" },
        { text: "Verbania", value: "Verbania" },
        { text: "Altre", value: "Altre" },
      ],
      hours: [
        { text: "00", value: "00" },
        { text: "01", value: "01" },
        { text: "02", value: "02" },
        { text: "03", value: "03" },
        { text: "04", value: "04" },
        { text: "05", value: "05" },
        { text: "06", value: "06" },
        { text: "07", value: "07" },
        { text: "08", value: "08" },
        { text: "09", value: "09" },
        { text: "10", value: "10" },
        { text: "11", value: "11" },
        { text: "12", value: "12" },
        { text: "13", value: "13" },
        { text: "14", value: "14" },
        { text: "15", value: "15" },
        { text: "16", value: "16" },
        { text: "17", value: "17" },
        { text: "18", value: "18" },
        { text: "19", value: "19" },
        { text: "20", value: "20" },
        { text: "21", value: "21" },
        { text: "22", value: "22" },
        { text: "23", value: "23" },
      ],
      minutes: [
        { text: "00", value: "00" },
        { text: "15", value: "15" },
        { text: "30", value: "30" },
        { text: "45", value: "45" },
      ],
      submitted: false,
    };
  },
  watch: {
    event_data_date() {
      var dayjs = require("dayjs");
      let day = dayjs(this.event_data_date).format("dddd");
      // console.log("Day: ", day);

      var english_days = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];
      var italian_days = [
        "Lunedì",
        "Martedì",
        "Mercoledì",
        "Giovedì",
        "Venerdì",
        "Sabato",
        "Domenica",
      ];

      let day_position = english_days.indexOf(day);
      // console.log("day_position: ", day_position + 1);

      // Set day
      this.event.data.day = italian_days[day_position];

      // Set date
      this.event.data.date = dayjs(this.event_data_date).format("DD/MM/YYYY");
      // console.log("Date: ", this.event.data.date);
    },
  },
  methods: {
    createEvent() {
      let new_event = {
        ...this.event.data,
        occupied_seats: 0,
        users: [],
      };

      EventsDataService.create(new_event)
        .then(() => {
          // console.log("Created new item successfully!");
          this.submitted = true;
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    },

    newCreateEvent() {
      this.event = {
        data: {
          title: "",
          subtitle: "",
          day: "",
          date: "",
          time: {
            hour: "",
            minute: "",
          },
          // time_hour: null,
          // time_minute: null,
          city: "",
          location: "",
          seats: 0,
          draft: false,
          video_conference: false,
        },
      };
      this.submitted = false;
    },
  },
};
</script>